/* @import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins", sans-serif;
  font-size: small;
  /* overflow-x: hidden; */
}

.bg-light {
  background-image: url(./assets/bg.jpg);
}

#cmf_section {
  overflow-x: hidden;
}

.h6-style {
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.row .text-secondary {
  color: #239b5e !important;
}

.sidebar-nav .nav-link {
  color: white !important;
}

.sidebar {
  background-color: #239b5e;

  /* background-color: linear-gradient(
    89.58deg,
    rgba(5, 178, 89, 0.86) 1.5%,
    rgba(3, 140, 72, 0.86) 99.65%
  ); */
}

.sidebar-nav .nav-link.active {
  background-color: rgba(238, 61, 66) !important;
}

.btn-primary {
  background-color: #d52933 !important;
  color: white !important;
  border: none;
}
